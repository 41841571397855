/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import './app/app.scss';

body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

swiper-container {
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #3880ff);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    white-space: nowrap;
    height: 100%;

    font-size: 14px;

    text-align: center;
    box-sizing: border-box;
}

swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.swiper-slide {
    display: block;
}
.swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 20px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 20px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}
ion-list-header {
    font-size: 18px;
}

ion-label {
    font-size: 16px;
}

.color-green {
    color: #388e3c;
}

.color-grey {
    color: #d3d3d3;
}

.profile-pic-container {
    position: relative;
}

.mini-avatar {
    height: 42px;
    width: 42px;
}

.mini-avatar2 {
    height: 28px;
    width: 28px;
}

ion-avatar {
    text-align: right;
    margin: 4px;
    width: 64px;
    height: 64px;
}

.md .status-icon {
    font-size: 10px;
    line-height: 0rem;
}

.ios .status-icon {
    font-size: 10px;
    line-height: 0rem;
}
.status-icon-mini {
    font-size: 10px;
    line-height: 0rem;
}
.dot {
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    right: 4px;
}

.dot-mini {
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    right: 4px;
}

:root {
    --present: #43a047;
    --tardy: #c77f4b;
    --excused: #41a8d4;
    --permitted: #7e57c1;
    --absent: #d32f2f;
    --daily-absent: #990000;
    --planned: #dedede;
    --not-taken: #607d8b;
}

.attendance_attended {
    background-color: var(--present);
    color: white !important;
    border-radius: 2px !important;
}

.attendance_tardy {
    background-color: var(--tardy);
    color: white !important;
    border-radius: 2px !important;
}

.attendance_absent {
    background-color: var(--absent);
    color: white !important;
    border-radius: 2px !important;
}

.attendance_permitted {
    background-color: var(--permitted);
    border-radius: 2px !important;
}

.attendance_excused {
    background-color: var(--excused);
    border-radius: 2px !important;
    color: white;
}

.attendance_daily_absent {
    background-color: var(--daily-absent);
    border-radius: 2px !important;
    color: white !important;
}

.attendance_not_yet {
    background-color: var(--planned);
    border-radius: 2px !important;
    color: gray;
}

.attendance_attended_color {
    color: var(--present);
}

.attendance_tardy_color {
    color: var(--tardy);
}

.attendance_absent_color {
    color: var(--absent);
}

.attendance_permitted_color {
    color: var(--permitted);
}

.attendance_excused_color {
    color: var(--excused);
}

.attendance_daily_absent_color {
    color: var(--daily-absent);
}

.attendance_not_yet_color {
    color: var(--planned);
}

.toolbar-shadow {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

/* Meeting status */
.created-status {
    background-color: #546e7a;
    color: #ffffff !important;
    padding: 2px;
}

.published-status {
    background-color: #4caf50;
    color: #ffffff !important;
    padding: 2px;
}

.started-status {
    background-color: #ffc107;
    color: #263238 !important;
    padding: 2px;
}

.ended-status {
    background-color: #1976d2;
    color: #ffffff !important;
    padding: 2px;
}

.canceled-status {
    background-color: #f44336;
    color: #ffffff !important;
    padding: 2px;
}

input,
textarea {
    background: transparent;
    /* Autofill color fix */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-background-clip: text;
        transition: background-color 5000s !important;
    }

    [data-autocompleted] {
        background-color: transparent !important;
    }
}
